<template>
  <v-card class="pa-0 pb-10" tile>
    <Loader v-if="loading" />
    <template v-else>
      <v-card-title>
        <h4 class="primary--text">
        {{ $t('reviews.title') }}
        </h4>
      </v-card-title>
      <div class="background px-6">
        <v-tabs v-model="activeTab" :height="60" background-color="background" show-arrows hide-slider center-active>
          <div class="d-flex align-center tabSeparator">
            <v-tab v-html="$t('reviews.tab1')" :ripple="false">
            </v-tab>
          </div>
          <div class="d-flex align-center tabSeparator">
            <v-tab v-html="$t('reviews.tab2')" :ripple="false">
            </v-tab>
          </div>
          <div class="d-flex align-center tabSeparator">
            <v-tab v-html="$t('reviews.tab3')" :ripple="false">
            </v-tab>
          </div>
        </v-tabs>
      </div>
      <v-tabs-items v-model="activeTab">
        <v-tab-item>
          <Rate />
        </v-tab-item>
        <v-tab-item>
          <Waiting />
        </v-tab-item>
        <v-tab-item>
          <Summary />
        </v-tab-item>
      </v-tabs-items>
    </template>
  </v-card>
</template>
<script>
import Loader from '@/components/Loader'
import Summary from './Tabs/Summary.vue'
import Rate from './Tabs/Rate.vue'
import Waiting from './Tabs/Waiting.vue'
export default {
  name: 'Reviews',
  components: {
    Loader,
    Summary,
    Rate,
    Waiting
  },
  data () {
    return {
      loading: false,
      activeTab: 0
    }
  },
  async created () {
  },
  computed: {},
  methods: {}
}
</script>
<style scoped>
.v-tab::before {
    display: block;
    content: attr(title);
    font-weight: bold;
    height: 0;
    overflow: hidden;
    visibility: hidden;
}

/* .v-tab--active, */
.v-tab:hover {
  /* font-weight: bold; */
  color: var(--v-primary-base) !important;
  text-shadow: 0 0 .7px var(--v-primary-base), 0 0 .7px var(--v-primary-base);
}

.v-tab--active:hover,
.v-tab--active {
  font-weight: bold;
  color: var(--v-primary-base) !important;
  text-shadow: none;
}

.tabSeparator {
  border-left: solid 1px var(--v-separator-base);
  margin: 10px 0;
}

.tabSeparator:last-child {
  border-right: solid 1px var(--v-separator-base);
  margin: 10px 0;
}
</style>
