var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card-text',[_c('v-form',{ref:"form"},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('label',[_vm._v(_vm._s(_vm.$t('reviews.place')))]),_c('v-text-field',{attrs:{"prepend-inner-icon":"$magnify","outlined":"","dense":"","single-line":"","hide-details":"auto"},model:{value:(_vm.searchInputValue),callback:function ($$v) {_vm.searchInputValue=$$v},expression:"searchInputValue"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('DatePicker',{attrs:{"label":_vm.$t('filters.dateFrom')},model:{value:(_vm.params.dateFrom),callback:function ($$v) {_vm.$set(_vm.params, "dateFrom", $$v)},expression:"params.dateFrom"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('DatePicker',{attrs:{"label":_vm.$t('filters.dateTo')},model:{value:(_vm.params.dateTo),callback:function ($$v) {_vm.$set(_vm.params, "dateTo", $$v)},expression:"params.dateTo"}})],1),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"12","md":"2","offset-md":"3"}},[_c('v-checkbox',{staticClass:"mt-3",attrs:{"hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.$t('freightList.showOnlyMain')))])]},proxy:true}]),model:{value:(_vm.params.showOnlyMine),callback:function ($$v) {_vm.$set(_vm.params, "showOnlyMine", $$v)},expression:"params.showOnlyMine"}})],1)],1)],1)],1),_c('v-divider'),_c('data-table',{ref:"MyCompanyRate",attrs:{"headers":_vm.headers,"endpoint":"/Transaction/GetMyCompanyTransactionsWaitingToReview","additionalParams":{ dateFrom: _vm.params.dateFrom, dateTo: _vm.params.dateTo, showOnlyMine: _vm.params.showOnlyMine },"search":_vm.searchInputValue,"serverPagination":""},scopedSlots:_vm._u([{key:"item.state",fn:function(ref){
var item = ref.item;
return [(_vm.userCommonData.companyId === item.provider.companyId)?_c('div',[(_vm.userCommonData.id === item.provider.userId)?_c('div',{staticClass:"d-flex align-center pointer",on:{"click":function($event){return _vm.reviewClient(item.orderId)}}},[_c('v-icon',{attrs:{"size":"26"}},[_vm._v("$client")]),_c('div',{staticClass:"ml-4"},[_vm._v(" "+_vm._s(_vm.$t('reviews.rateClient'))+" "),_c('v-rating',{attrs:{"length":"5","size":"16","color":"accent","readonly":""}})],1)],1):_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{attrs:{"size":"26"}},[_vm._v("$provider")]),_c('div',{staticClass:"ml-4"},[_vm._v(" "+_vm._s(_vm.$t('reviews.provider'))+" ")])],1)]):_c('div',[(_vm.userCommonData.id === item.client.userId)?_c('div',{staticClass:"d-flex align-center pointer",on:{"click":function($event){return _vm.reviewProvider(item.orderId)}}},[_c('v-icon',{attrs:{"size":"26"}},[_vm._v("$provider")]),_c('div',{staticClass:"ml-4"},[_vm._v(" "+_vm._s(_vm.$t('reviews.rateProvider'))+" "),_c('v-rating',{attrs:{"length":"5","size":"16","color":"accent","readonly":""}})],1)],1):_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{attrs:{"size":"26"}},[_vm._v("$client")]),_c('div',{staticClass:"ml-4"},[_vm._v(" "+_vm._s(_vm.$t('reviews.client'))+" ")])],1)])]}},{key:"item.reviewAvailableTo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.countDays(item.reviewAvailableTo))+" ")]}},{key:"item.forwarder",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text"},[_vm._v(_vm._s(item.forwarder.fullName))])]}},{key:"item.loadingPlaces",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex align-center"},[_c('CountryFlag',{staticClass:"mr-1",attrs:{"country":item.loadingPlaces[0].country}}),_c('div',{staticClass:"d-flex flex-column"},[_c('span',[_vm._v(" "+_vm._s(item.loadingPlaces[0].country.toUpperCase())+", "),_c('span',[_vm._v(_vm._s(item.loadingPlaces[0].postalCode))]),_vm._v(" "),_c('br'),_vm._v(" "+_vm._s(item.loadingPlaces[0].city)+" ")]),_c('div',{staticClass:"lightText--text"},[_c('span',[_vm._v(_vm._s(_vm._f("dateFormat")(item.loadingPlaces[0].date))+" ")]),(item.loadingPlaces[0].from && item.loadingPlaces[0].to)?_c('span',[_vm._v(_vm._s(item.loadingPlaces[0].from)+" - "+_vm._s(item.loadingPlaces[0].to))]):_vm._e()]),(item.loadingDistanceFromFilter)?_c('div',[_c('span',[_vm._v(_vm._s(item.loadingDistanceFromFilter))])]):_vm._e()])],1),(item.loadingPlaces.length > 1)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"more-data ml-2"},'div',attrs,false),on),[_vm._v(" +"+_vm._s(item.loadingPlaces.length - 1)+" ")])]}}],null,true)},_vm._l((item.loadingPlaces),function(location,index){return _c('div',{key:index},[(index !== 0)?_c('div',{staticClass:"d-flex align-center",staticStyle:{"line-height":"1.25"}},[_c('CountryFlag',{staticClass:"mr-1",attrs:{"country":location.country}}),_c('div',{staticClass:"d-flex flex-column"},[_c('span',[_vm._v(_vm._s(location.country.toUpperCase())+", "+_vm._s(location.postalCode)+" "+_vm._s(location.city))]),_c('span',[_vm._v(_vm._s(_vm._f("dateFormat")(location.date))),(location.from && location.to)?_c('span',[_vm._v(" "+_vm._s(location.from)+" - "+_vm._s(location.to))]):_vm._e()])])],1):_vm._e()])}),0):_vm._e()],1)]}},{key:"item.unloadingPlaces",fn:function(ref){
var item = ref.item;
return [(item.unloadingPlaces.length > 0)?_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex align-center"},[_c('CountryFlag',{staticClass:"mr-1",attrs:{"country":item.unloadingPlaces[0].country}}),_c('div',{staticClass:"d-flex flex-column"},[_c('div',[_c('span',[_vm._v(" "+_vm._s(item.unloadingPlaces[0].country.toUpperCase())+", "+_vm._s(item.unloadingPlaces[0].postalCode)),_c('br'),_vm._v(" "+_vm._s(item.unloadingPlaces[0].city)+" ")]),_c('div',{staticClass:"lightText--text"},[_c('span',[_vm._v(_vm._s(_vm._f("dateFormat")(item.unloadingPlaces[0].date))+" ")]),(item.unloadingPlaces[0].from && item.unloadingPlaces[0].to)?_c('span',[_vm._v(_vm._s(item.unloadingPlaces[0].from)+" - "+_vm._s(item.unloadingPlaces[0].to))]):_vm._e()]),(item.unloadingDistanceFromFilter)?_c('div',[_c('span',[_vm._v(_vm._s(item.unloadingDistanceFromFilter))])]):_vm._e()])])],1),(item.unloadingPlaces.length > 1)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"more-data ml-2"},'div',attrs,false),on),[_vm._v("+"+_vm._s(item.unloadingPlaces.length - 1))])]}}],null,true)},_vm._l((item.unloadingPlaces),function(location,index){return _c('div',{key:index},[(index !== 0)?_c('div',{staticClass:"d-flex align-center",staticStyle:{"line-height":"1.25"}},[_c('CountryFlag',{staticClass:"mr-1",attrs:{"country":location.country}}),_c('div',{staticClass:"d-flex flex-column"},[_c('span',[_vm._v(_vm._s(location.country.toUpperCase())+", "+_vm._s(location.postalCode)+" "+_vm._s(location.city))]),_c('span',[_vm._v(_vm._s(_vm._f("dateFormat")(location.date))+" "),(location.from && location.to)?_c('span',[_vm._v(_vm._s(location.from)+" - "+_vm._s(location.to))]):_vm._e()])])],1):_vm._e()])}),0):_vm._e()],1):_c('div',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm.$t('myVehicle.anyDestination'))+" ")])]}},{key:"item.dealDate",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.dealDate,'DD.MM.YYYY'))+" "),_c('br'),_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.dealDate,'HH:mm'))+" ")])]}},{key:"item.contractor",fn:function(ref){
var item = ref.item;
return [(item.contractor)?_c('div',{staticClass:"d-flex align-center cursor-pointer"},[_c('StatusAvatar',{staticClass:"mr-2",attrs:{"userId":item.contractor.id,"size":26,"showStatus":""}}),_c('div',{staticClass:"d-flex flex-column"},[_c('div',[_vm._v(_vm._s(item.contractor.name)+" "+_vm._s(item.contractor.surname))]),_c('v-tooltip',{attrs:{"bottom":"","min-width":"320px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"lightText--text"},'div',attrs,false),on),[_vm._v(_vm._s(_vm._f("trimText")(item.contractor.company.name,20)))])]}}],null,true)},[_c('v-row',{staticClass:"mt-1"},[_c('v-col',{attrs:{"cols":"6"}},[_c('p',[_vm._v(_vm._s(item.contractor.company.name)),_c('br'),_vm._v(" NIP: "+_vm._s(item.contractor.company.vatNumber))]),_c('p',[_vm._v(_vm._s(_vm.$t('offerDetails.address'))+":"),_c('br'),_vm._v(" "+_vm._s(item.contractor.company.street)),_c('br'),_vm._v(" "+_vm._s(item.contractor.company.zipCode)+", "+_vm._s(item.contractor.company.city))])]),_c('v-col',{attrs:{"cols":"6"}},[_vm._v(" "+_vm._s(_vm.$t('offerDetails.averageRating'))+": "),_c('v-rating',{staticClass:"mb-4",attrs:{"length":"5","size":"16","color":"accent","value":+item.contractor.company.averageRating}}),_vm._v(" "+_vm._s(_vm.$t('offerDetails.paymentRating'))+": "),_c('v-rating',{attrs:{"length":"5","size":"16","color":"accent","value":+item.contractor.company.averagePaymentRating}})],1)],1)],1)],1)],1):_vm._e()]}},{key:"item.chatIcon",fn:function(ref){
var item = ref.item;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [(item.contractor)?_c('v-icon',{attrs:{"icon":"","color":hover ? 'primary' : '',"size":"18"},on:{"click":function($event){$event.stopPropagation();return _vm.startConversationWithUser(item)}}},[_vm._v("$conversation")]):_vm._e()]}}],null,true)})]}},{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("rating.noData"))+" ")]},proxy:true}],null,true)}),_c('ReviewClient',{ref:"reviewClient",attrs:{"orderId":_vm.orderId},on:{"add":_vm.refresh}}),_c('ReviewProvider',{ref:"reviewProvider",attrs:{"orderId":_vm.orderId},on:{"add":_vm.refresh}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }